import { useState, useEffect } from 'react'

import { logout } from '../services/api'
import { getUser } from '../services/auth'

const useLogoutSubscriptions = (callback) => {
  const [sub, setSub] = useState(null)
  useEffect(() => {
    if (sub) return
    const getSub = async () => {
      try {
        const user = await getUser()
        if (!user) return
        const subId = user.username
        setSub(await logout(subId, callback))
      } catch {
        return
      }
    }
    getSub()

    return () => sub && sub.unsubscribe()
  }, [callback, sub])
  return sub
}

export default useLogoutSubscriptions
