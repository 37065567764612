import React from "react";
import styled from "styled-components";
import LandingPageHeading from "./LandingPageHeading";
import CommonFormItem from "./CommonForms/CommonFormItem";

const StyledCommonFormsModern = styled.div`
  background-color: #FFF;
  padding: 40px 0;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    margin: 100px 0;

    .wrapper-common-forms {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .item {
          margin-right: 15px;
        }
    }
 }
`;

const forms = [
  { text: 'Partial Prepayment', href: './forms/2024/Partial_Prepayment_Form.pdf' },
  { text: 'Occupancy Certification', href: './forms/2024/Annual_Occupancy_Certificate.pdf' }
];

export const CommonFormsModern = () => {
  return (
    <StyledCommonFormsModern>
      <LandingPageHeading title="Common Forms" />
      <div className='wrapper-common-forms'>
        {
          forms.map((item, index) => (
            <div className="item">
              <CommonFormItem key={index} form={item} />
            </div>
          ))
        }
      </div>
    </StyledCommonFormsModern>
  );
};
