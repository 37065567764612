import { lazy, Suspense } from 'react';
import LoadingPage from '../Components/LoadingPage';
const ApiProviderAuthenticated = lazy(() => import('./ApiContextAuthenticated'));
export const ApiProvider = ({ isAuthenticated, children }) => {
  return isAuthenticated ? (
    <Suspense fallback={<div> <LoadingPage /> </div>}>
      <ApiProviderAuthenticated>{children}</ApiProviderAuthenticated>
    </Suspense>
  ) : (
    <>{children}</>
  );
};