import React from 'react'
import { Helmet } from 'react-helmet'

const Title = ({ children }) => {
  if (document.title.includes(children)) return <React.Fragment />
  return (
    <Helmet>
      <title>{`Borrower Portal - ${children}`}</title>
    </Helmet>
  )
}

export default Title
