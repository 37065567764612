import React from "react";
import styled from "styled-components";
import InforCircleIcon from "../assets/info-circle-icon";
import { buildTestID } from "../Utils/utils";

const StyledPrepaymentBanner = styled.div`
  align-items: center;
  background-color: #475468;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  position: relative;

  a {
    color: #000;
  }

  & .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  & .group {
    height: 24px;
    position: relative;
    width: 24px;
  }

  & .vector {
    height: 19px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 19px;
  }

  & .text-wrapper {
    color: #ffffff;
    font-family: "Lato-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    width: fit-content;
    margin-bottom: 0;
  }

  & .div-wrapper {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
    margin: 20px 0;
  }

  & .text-wrapper-2 {
    color: #475468;
    font-family: "Lato-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
  }

  @media (min-width: 1281px) {
    flex-direction: row;
    justify-content: center;

    & .div {
      margin-right: 20px;
    }

    & .text-wrapper {
      white-space: nowrap;
    }
  }
`;

export const PrepaymentBanner = () => {
  return (
    <StyledPrepaymentBanner>
      <div className="div">
        <div className="group">
          <InforCircleIcon color="white" />
        </div>
        <p className="text-wrapper">If you wish to make a voluntary prepayment to your loan balance, or if you have a required payment due on your approved Repayment Plan</p>
      </div>
      <div className="div-wrapper">
        <div className="text-wrapper-2"><a href="https://secure.directbiller.com/pbills/payer/welcomeDirect.do?h=1gzzt5f3tn" target="_blank" rel="noreferrer" data-testid={buildTestID('banner_repayment_link', '/', 'repayment_link')}>Click Here</a></div>
      </div>
    </StyledPrepaymentBanner>
  );
};
