// Loan

export const extractLoan = (loanGraphQlResponse) => loanGraphQlResponse && loanGraphQlResponse.data
    && loanGraphQlResponse.data.loan && loanGraphQlResponse.data.loan.loanData

export const extractServicer = loanGraphQlResponse => {
    const loan = extractLoan(loanGraphQlResponse)
    return loan && loan.legalEntity.legalEntity[0].servicer[0]
}

export const extractBorrowerAndCoborrowers = (loanGraphQlResponse, personId) => {
    const loan = extractLoan(loanGraphQlResponse)
    const individuals = loan && loan.individual && loan.individual.individual
    if (!individuals) return null
    return [individuals.find(x => x.personId?.toString() === personId?.toString()),
    individuals.filter(x => x.personId?.toString() !== personId?.toString() && (x.roleTypeArray && !x.roleTypeArray.toLowerCase().includes('contact')))]
}

export const extractMonetaryEventSummary = loanGraphQlResponse => {
    const loan = extractLoan(loanGraphQlResponse)
    return  loan.monetaryEventSummary.monetaryEventSummary.find(x => x.currentBalanceIndicator === 'true')
}
