import React from 'react'
import styled from 'styled-components'

const StyledAdvanceRequest = styled.div`
  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 109px;
    line-height: 96px;
    letter-spacing: -1.5px;
  }

  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 74px;
    letter-spacing: -0.5px;
  }

  h3,
  .heading-3 {
    font-style: normal;
    font-weight: normal;
    font-size: 52px;
    line-height: 60px;
  }

  h4,
  .heading-4 {
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 42px;
    letter-spacing: 0.25px;
  }

  h5,
  .heading-5 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
  }

  h6,
  .heading-6 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin: 48px auto 34px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    text-transform: capitalize;
  }

  .body-1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .body-2 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }

  .subtitle-2 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .button {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .caption {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .break_line {
    border-top: 0.5px solid ${({ theme }) => theme.colors.black12};
    margin: 40px 0 30px;
  }

  .bold {
    font-weight: bold;
  }

  .input {
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
  }

  aside.ScrollUpButton__Container:focus {
    outline: 5px solid ${({ theme }) => theme.colors.primary};
  }
`

const StyleGuide = ({ children }) => {
  return <StyledAdvanceRequest>{children}</StyledAdvanceRequest>
}

export default StyleGuide
