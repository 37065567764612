// import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// import 'core-js/es/map'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import App from './Containers/App'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter } from 'react-router-dom'

let AppWithLaunchDarkly = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
})(App)
const root = createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <AppWithLaunchDarkly />
 </BrowserRouter>
)