import { Auth } from 'aws-amplify'

const awsExports = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    // OPTIONAL - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    // (optional) - customized storage object
    storage: localStorage 
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_USERS_WRITE_BUCKET_ID, //REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_AWS_REGION //OPTIONAL -  Amazon service region
    },
  },
  API: {
    accountValidationApiUrl: process.env.REACT_APP_EXTERNAL_INVOKE_URL,
    verificationApiUrl: process.env.REACT_APP_EXTERNAL_INVOKE_URL_REGISTRATION_STEP_1,
    verificationApiKey: process.env.REACT_APP_EXTERNAL_API_KEY_VALUE,
    verificationUpbUrl: process.env.REACT_APP_EXTERNAL_INVOKE_URL_REGISTRATION_STEP_2,
  },
 
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession())
      .getIdToken()
      .getJwtToken(),
  }),
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URIS,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

export default awsExports
