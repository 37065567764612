import gql from 'graphql-tag'

export const getLoan = () =>
  gql`
    query Loan {
      loan {
        loanData {
          loanId
          loanStatusCode
          loanStatusDescription
          loanStatusType
          anniversaryDate
          disclosures {
            paperlessChangeDate
            paperlessIndicator
          }
          product {
            payPlanType
            productName
          }
          advanceBankAccount {
            accountId
            paymentType
          }
          legalEntity(celink_loan_number: "#loan_number#") {
            legalEntity {
              legalName
              roleTypeArray
              phone {
                phoneNumber
                phoneType
              }
              servicer {
                clientName
                servicerId
                subservicerId
                servicedAs
                refiContactDetails
              }
            }
          }
          property {
            address1
            address2
            city
            postalCode
            state
          }
          individual(celink_loan_number: "#loan_number#") {
            individual {
              address {
                address1
                address2
                addressTypeArray
                city
                postalCode
                state
              }
              firstName
              lastName
              birthDate
              borrower { 
                occupancyCertNextDueDate
                occupancyReceivedFlag
                occupancyCertReceivedDate
             }
              personId
              roleTypeArray
              phone {
                phoneNumber
                phoneType
                primaryIndicator
                usageType
              }
            }
          }
          monetaryEventSummary(celink_loan_number: "#loan_number#") {
            monetaryEventSummary {
              currentBalanceIndicator
              currentInterestRate
              firstYearLOCAvailable
              locNetAmount
              upbAmount
              serviceFeesetAside
              principalLimit
              serviceFeesetAside
            }
          }
        }
      }
    }
  `

export const getTransactions = () =>
  gql`
    query Transactions {
      loan {
        loanData {
          monetaryEvents(celink_loan_number: "#loan_number#") {
            monetaryEvents {
              codeDescription
              currentInterestRate
              endingBalance
              interestAmount
              maxClaimAmount
              mipAmount
              monetaryEventId
              poolId
              servicingFees
              statusCode
              transactionAmount
              transactionCode
              transactionCount
              transactionCreationDate
              transactionEffectiveDate
              transactionTotal
            }
          }
        }
      }
    }
  `

export const saveCognitoId = (personId, cognitoId) =>
  gql`
mutation RegisterCognitoId {
  registerCognitoPoolId(personId: "${personId}", identityId: "${cognitoId}") {
    message
    responseCode
  }
}
`

export const requestCode = (sendType) => {
  if (sendType !== 'phone' && sendType !== 'email')
    throw Error('sendType parameter must either be: phone or email')
  return gql`
    query{
        requestCode(send_type:"${sendType}"){
            result
        }
      }
  `
}

export const confirmCode = (code) =>
  gql`
    query{
        confirmCode(code:"${code}"){
            result
            token
            message
        }
      }
  `

export const putLocRequest = (
  loanNumber,
  requestDateTime,
  submissionType,
  drawAmount,
  drawSigner,
  payType,
) =>
  gql`
    mutation putLOCRequest {
        putLOCRequest(
            celink_loan_number:"${loanNumber}", request_received_datetime: "${requestDateTime}", submission_type:"${submissionType}",
             draw_amount: "${drawAmount}", draw_signer: "${drawSigner}", disbursement_method: "${payType}")
          { details {
               loannumber
               request_id
               request_submission_time
               request_amount
             } 
             }
    }
  `
export const getLocStatus = (loanNumber) =>
  gql`
    query{
        getLOCStatus(celink_loan_number: "${loanNumber}"){
        success
        statusCode
        message
        details{loannumber}
        info {
            request_id
            draw_request_amount
            request_datetime
            submission_type
            trans_id
            total_cash
            trans_effective_date
            request_status
            request_status_date
            payType : disbursement_method
        }
      }
    }
  `
export const getLocEligibility = (loanNumber) =>
  gql`
    query{
      getLOCEligibility(celink_loan_number: "${loanNumber}") {
        success
        statusCode
        message
        details {   
          loannumber
        }
        info {
          eligible
          pending
          history
          maxOnlineRequestAmount,
          maxRequestAmount,
          pendingTransactionAmount,
          adjustedRequestAmount,
          occCertDue
	        boCanSeeNbs
        }
      }
    }
  `

export const getPayOffEligibility = (loanNumber) =>
  gql`
    query {
      getPayoffEligibility(celink_loan_number: "${loanNumber}") {
        details {
          loannumber
        }
        message
        statusCode
        success
        info {
          eligible
        }
      }
    }
  `

export const getPayoffQuote = (loanNumber, payoffDate) =>
  gql`
    query {
      getPayoffQuote(celink_loan_number: "${loanNumber}", payoff_date: "${payoffDate}") {
        success
        statusCode
        message
        details {
          loannumber
          payoff_presignedurl
        }
      }
    }
  `

  export const putPayoffRequest =  gql`
  mutation putPayoffRequest($destination: String = "",  $requestedGoodThroughDate: String = "", $type: DeliveryDestinationType!) {
    putPayoffRequest(deliveryDestinations: {destination: $destination, type: $type}, requestedGoodThroughDate: $requestedGoodThroughDate) {
      responseData {
        result,
        responseCode,
        message
      },
      details {
        loanNumber
        payoffRequestGuid
        payoffRequestId
        payoffRequestSubmissionTime
      }
    }
  }
  `
  
export const logoutSubscription = (subId) =>
  gql`
    subscription logout {
      logout(subId:"${subId}") {
        subId
      }
    }
  `

export const putStatementPreference = (statementPreference) =>
  gql`
    mutation UpdatePaperless {
      updatePaperless(optin: "${statementPreference}") {
        message
        responseCode
      }
    }
  `

export const postESignature = gql`
mutation SubmitESignatureRequest ($input:SubmitESignatureInput!) {
  submitESignature(input: $input) {
    link
    responseData {
      message
      responseCode
    }
  }
}
`

export const getAgreementSignature = gql`
query GetAgreementSignature ($descriptionId: ID!, $personId: ID!) {
  agreementSignatureConsent(descriptionId: $descriptionId, personId:  $personId) {  
      agreementSignature {
          agreementId
          createdBy
          creationTimestamp
          firstName
          lastName
          loginDetails
          personId
          signatureId
          signatureText
          signatureTimestamp
          expirationDate
      }
      responseData {
          message
          responseCode
      }
  }
}
`

export const updateAgreementSign = (signatureId, email) =>
  gql`
  mutation MyMutation {
    updateAgreementSign(input: { signatureId: ${signatureId}, modifiedBy: "${email}"}) {
      message
      responseCode
    }
  }
`
export const getAnnualPrivacyNotice = (loanNumber) =>
  gql`
  query GetNotice {
    annualPrivacyNotices {
      annualPrivacyNotices {
        effectiveDate
        endDate
        policyId
        policyName
        policyVersion
      }
    }
  }
  `

export const postIndividualContact = (cellConsent, mobilePhoneNumber) => gql`
  mutation updateIndividualContact {
    updateIndividualContact(cellConsent: "${cellConsent}", mobilePhoneNumber: "${mobilePhoneNumber}") {
      responseData {
        message
        responseCode
      }
    }
  }
`

export const postIndividualHomePhoneNumber = (cellConsent, homePhoneNumber) => gql`
  mutation updateIndividualContact {
    updateIndividualContact(cellConsent: "${cellConsent}",  homePhoneNumber: "${homePhoneNumber}") {
      responseData {
        message
        responseCode
      }
    }
  }
`

export const postIndividualMobilePhoneNumber = (cellConsent, mobilePhoneNumber) => gql`
  mutation updateIndividualContact {
    updateIndividualContact(cellConsent: "${cellConsent}",  mobilePhoneNumber: "${mobilePhoneNumber}") {
      responseData {
        message
        responseCode
      }
    }
  }
`

export const postIndividualWorkPhoneNumber = (cellConsent, workPhoneNumber) => gql`
  mutation updateIndividualContact {
    updateIndividualContact(cellConsent: "${cellConsent}", workPhoneNumber: "${workPhoneNumber}") {
      responseData {
        message
        responseCode
      }
    }
  }
`

export const getValidateIdentity = (loanNumber, ssn, dateOfBirth, zipCode) => gql`
  query MyQuery {
    validateIdentity(celink_loan_number: "${loanNumber}", last4SSN: "${ssn}", dateOfBirth: "${dateOfBirth}", zipCode: "${zipCode}") {
      isValid
    }
  }
`
