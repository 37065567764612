import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const LeftChevronIcon = ({ color = 'primary', className }) => {
  return (
    <StyledSVG
      className={className}
      width="8"
      height="12"
      viewBox="0 0 8 12"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.70998 9.87998L2.82998 5.99998L6.70998 2.11998C7.09998 1.72998 7.09998 1.09998 6.70998 0.70998C6.31998 0.31998 5.68998 0.31998 5.29998 0.70998L0.70998 5.29998C0.31998 5.68998 0.31998 6.31998 0.70998 6.70998L5.29998 11.3C5.68998 11.69 6.31998 11.69 6.70998 11.3C7.08998 10.91 7.09998 10.27 6.70998 9.87998Z" />
    </StyledSVG>
  )
}

export default LeftChevronIcon
