import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { buildTestID } from '../Utils/utils'
import LiborModal from '../Common/LiborModal.js'
import useWindowSize from "../Hooks/useWindowSize.js";

const StyledLandingPageBannerModern = styled.div`
  & .div {
    background-color: #475468e6;
    border-radius: 20px;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: 300px;
    margin-right: 15px;
    padding: 30px 40px;
    overflow-x: auto;

    :-webkit-scrollbar {
      display: none;
    }
  }

  & .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
  }

  & .text-wrapper {
    color: var(--White, #FFF);
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.45);
    font-family: "Lato", Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 120% */
  }

  & .for-information {
    align-self: stretch;
    color: #ffffff;
    font-family: "Lato", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    text-shadow: 0px 1px 3px #00000073;
  }

  & .div-wrapper {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
  }

  & .text-wrapper-2 {
    color: #475468;
    font-family: "Lato", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
    > a {
      color: #475468;
    }
  }

  & .group {
    height: 20px;
    position: relative;
    width: 156px;
  }

  & .ellipse {
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  & .ellipse-1 {
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 20px;
  }

  & .ellipse-2 {
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    left: 68px;
    position: absolute;
    top: 0;
    width: 20px;
  }

  & .ellipse-3 {
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    left: 102px;
    position: absolute;
    top: 0;
    width: 20px;
  }

  & .ellipse-4 {
    border: 2px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    left: 136px;
    position: absolute;
    top: 0;
    width: 20px;
  }

  .active {
    background-color: #ffffff;
  }

  .carousel-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    overflow-x: auto;
  }

  @media (min-width: 1024px) {
    & .div {
      background-color: #475468e6;
      border-radius: 20px;
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 40px;
      position: relative;
      width: 540px;
      margin-right: 15px;
      padding: 30px 40px;
      overflow-x: auto;
    }

    & .text-wrapper {
      align-self: stretch;
      color: #ffffff;
      font-family: "Lato", Helvetica;
      font-size: 38px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 50px;
      margin-top: -1px;
      position: relative;
      text-shadow: 0px 1px 3px #00000073;
    }
  }

  @media (min-width: 1281px) {
    & .div {
      background-color: #475468e6;
      border-radius: 20px;
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 40px;
      position: relative;
      width: 100%;
      margin-right: 15px;
      padding: 30px 40px;
      overflow-x: auto;
    }

    & .text-wrapper {
      align-self: stretch;
      color: #ffffff;
      font-family: "Lato", Helvetica;
      font-size: 38px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 50px;
      margin-top: -1px;
      position: relative;
      text-shadow: 0px 1px 3px #00000073;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.large}) {
    display: flex;
    width: 430px;
    height: 460px;
    min-height: 450px;
    padding: 30px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    flex-shrink: 0;
    border-radius: 20px;
    background: rgba(71, 84, 104, 0.90);
  
    & .div {
      background-color: transparent;
      border-radius: 0;
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 40px;
      position: relative;
      width: 100%;
      padding: 0;
    }

    .text-wrapper {
      color: var(--White, #FFF);
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.45);
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
    }

    .for-information {
      color: var(--White, #FFF);
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.45);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 140% */
    }

    .group {
      width: 88px;
      height: 20px;
      position: relative;
    }
  }

  @media (min-width:760px) and (max-width:1024px) {
  & .div {
    width:49%;
  }
`;

export const LandingPageBannerModern = () => {
  const [showLiborModal, setShowLiborModal] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const { isSmall, isMedium } = useWindowSize()

  useEffect(() => {
    if (isSmall)
      return

    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      if (currentSlide === 1)
        setCurrentSlide(0)
      else
        setCurrentSlide(currentSlide + 1)
    }, 10000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [currentSlide]); // Empty dependency array ensures the effect runs only once

  return (
    <StyledLandingPageBannerModern>
      {isSmall || isMedium ?
        (
          <div className="carousel-wrapper">
             <div className="div">
                <div className="div-2">
                  <p className="text-wrapper">Insurance Claim/Loss Draft:</p>
                  <p className="for-information">
                  Has your property been damaged? Click below for assistance.                  
                  </p>
                </div>
                <div className="div-wrapper">
                  <div className="text-wrapper-2"><a href="claims" data-testid={buildTestID('here', '/home', 'claim')}>Read More</a></div>
                </div>
              </div>
              <div className="div">
              <div className="div-2">
                <p className="text-wrapper">Behind on Taxes and Insurance?</p>
                <p className="for-information">
                  Click below and select your state to review Homeowner’s Assistance Fund (HAF) guidelines and apply for financial assistance.
                </p>
              </div>
              <div className="div-wrapper">
                <div className="text-wrapper-2"><a href="https://www.ncsha.org/homeowner-assistance-fund/" data-testid={buildTestID('libor-modal', '/', 'libor-banner')}>Read More</a></div>
              </div>
            </div>
          </div>
        ) :
        (
          <>
          {currentSlide === 0 && (
              <div className="div">
                <div className="div-2">
                  <p className="text-wrapper">Insurance Claim/Loss Draft:</p>
                  <p className="for-information">
                  Has your property been damaged? Click below for assistance.
                  </p>
                </div>
                <div className="div-wrapper">
                  <div className="text-wrapper-2"><a href="claims" data-testid={buildTestID('here', '/home', 'claim')}>Read More</a></div>
                </div>
              </div>
            )}
            {currentSlide === 1 && (
              <div className="div">
                <div className="div-2">
                  <p className="text-wrapper">Behind on Taxes and Insurance?</p>
                  <p className="for-information">
                    Click below and select your state to review Homeowner’s Assistance Fund (HAF) guidelines and apply for financial assistance.
                  </p>
                </div>
                <div className="div-wrapper">
                  <div className="text-wrapper-2"><a href="https://www.ncsha.org/homeowner-assistance-fund/" data-testid={buildTestID('libor-modal', '/', 'libor-banner')}>Read More</a></div>
                </div>
              </div>
            )}
            
            <div className="group">
              <div className={`ellipse ${currentSlide === 0 ? 'active' : ''}`} onClick={() => { setCurrentSlide(0) }} />
              <div className={`ellipse-1 ${currentSlide === 1 ? 'active' : ''}`} onClick={() => { setCurrentSlide(1) }} />
            </div></>

        )}
      <LiborModal onHide={() => setShowLiborModal(false)} show={showLiborModal} />
    </StyledLandingPageBannerModern>
  );
};