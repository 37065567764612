import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const NoEyeIcon = ({ color = 'primary' }) => {
    return (
        <StyledSVG
            color={color}
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            fill="none">
           <path d="M11 4.49993C13.76 4.49993 16 6.73993 16 9.49993C16 10.0099 15.9 10.4999 15.76 10.9599L18.82 14.0199C20.21 12.7899 21.31 11.2499 22 9.48993C20.27 5.10993 16 1.99993 11 1.99993C9.73 1.99993 8.51 2.19993 7.36 2.56993L9.53 4.73993C10 4.59993 10.49 4.49993 11 4.49993ZM1.71 1.15993C1.32 1.54993 1.32 2.17993 1.71 2.56993L3.68 4.53993C2.06 5.82993 0.77 7.52993 0 9.49993C1.73 13.8899 6 16.9999 11 16.9999C12.52 16.9999 13.97 16.6999 15.31 16.1799L18.03 18.8999C18.42 19.2899 19.05 19.2899 19.44 18.8999C19.83 18.5099 19.83 17.8799 19.44 17.4899L3.13 1.15993C2.74 0.769932 2.1 0.769932 1.71 1.15993ZM11 14.4999C8.24 14.4999 6 12.2599 6 9.49993C6 8.72993 6.18 7.99993 6.49 7.35993L8.06 8.92993C8.03 9.10993 8 9.29993 8 9.49993C8 11.1599 9.34 12.4999 11 12.4999C11.2 12.4999 11.38 12.4699 11.57 12.4299L13.14 13.9999C12.49 14.3199 11.77 14.4999 11 14.4999ZM13.97 9.16993C13.82 7.76993 12.72 6.67993 11.33 6.52993L13.97 9.16993Z" fill="#5A5A5A"/>
        </StyledSVG>      
    )
}

export default NoEyeIcon
