import React, { useRef, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'

const IdleTimerComponent = ({ onTimeout, onLogout, timeInSeconds }) => {

  const idleTimer = useRef(null)

const getTimeUntilLogoutAlert = () => process.env.REACT_APP_TIME_UNTIL_LOGOUT_ALERT || 840

const getTimeUntilLogoutAfterAlert = () => process.env.REACT_APP_TIME_UNTIL_LOGOUT_AFTER_ALERT || 60

  useEffect(() => {
    if (idleTimer && idleTimer.current) idleTimer.current.reset()
  })
  const onIdle = (e) => {
    onTimeout(idleTimer.current)
  }

  const onAction = (e) => {
    if((new Date().getTime() - idleTimer.current.getLastActiveTime()) / 1000 >= parseInt(getTimeUntilLogoutAlert()) + parseInt(getTimeUntilLogoutAfterAlert()))
        onLogout()

    idleTimer.current.reset()
  }

  return (
    <IdleTimer
      ref={idleTimer}
      onIdle={onIdle}
      onAction={onAction}
      timeout={1000 * parseInt(timeInSeconds, 10)}
    />
  )
}

export default IdleTimerComponent
