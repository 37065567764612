import { useState, useEffect } from 'react'

import { getUser } from '../services/auth'

export const useLoggedInUser = (dependency = null) => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: true,
  })

  useEffect(() => {
    const saturateData = async () => {
      try {
        const user = await getUser()
        if (user)
          setData({
            data: user,
            loading: false,
            error: false,
          })
        else throw new Error('user does not exist')
      } catch (ex) {
        setData({ error: ex })
      }
    }
    saturateData()
  }, [dependency])

  return data
}
