import { useContext } from 'react'
import { ApiContext } from '../Contexts/ApiContextAuthenticated';

export const useApiContext = () => {
    const context = useContext(ApiContext);

    if (!context) {
        throw new Error("You need to wrap ApiProvider.");
    }

    return context;
}