import React from "react";
import styled from "styled-components";
import LandingPageHeading from "./LandingPageHeading";
import CommonQuestionItem from "./CommonQuestions/CommonQuestionItem";

const StyledCommonQuestionsModern = styled.div`
  background-color: #F8FBFF;
  flex-shrink: 0;
  padding: 40px 0;

  h3 {
    text-align: center;
  }

  .rectangle {
    background: linear-gradient(90deg, #f8fbff 0%, #9baac0 100%);
    display: flex;
    min-height: 1px;
    max-width: 323px;
    flex-direction: column;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    // background: url(./assets/background-common-questions.png) no-repeat;
    width: 100vw;
    height: 684.5px;
    padding-top: 217px;

    .wrapper-common-questions {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0 100px;

      > .item {
        width: 50%
      }
    }
 }

 @media (min-width: 1536px) {
  .wrapper-common-questions {
    padding: 0 400px;
    }
  }

 @media (min-width: ${({ theme }) => theme.breakPoints.xlarge}) {
  background-size: cover;
  width: 100vw;

  .wrapper-common-questions {
    padding: 0 20%;
    }
 }
`;

const questions = [
  { text: 'How do I communicate with you in a language other than English? Do you have a Language Assistance service?', href: '/common-questions#popular' },
  { text: 'How do I set up direct deposit?', href: '/common-questions#how-do-i-setup-direct-deposit' },
  { text: 'How do I request funds from my Line of Credit?', href: '/common-questions#how-do-i-withdraw-from-loc' },
  { text: 'How do I order a payoff quote?', href: '/common-questions#how-do-i-order-payoff-quote' },
  { text: 'How long before I get my money?', href: '/common-questions#how-long-before-i-get-money' },
  { text: 'How can I authorize a third party to receive information on my account?', href: '/common-questions#how-can-i-authorize-third-party' }
]

export const CommonQuestionsModern = () => {
  return (
    <StyledCommonQuestionsModern>
      <LandingPageHeading title="Common Questions" />
      <div className='wrapper-common-questions'>
        {
          questions.map((item, index) => (
            <div className="item" key={index}>
              <CommonQuestionItem question={item} />
            </div>
          ))
        }
      </div>
    </StyledCommonQuestionsModern>
  );
};
