import React from 'react'
import styled from 'styled-components';

const Main = styled.div`
    margin: 50px 20px;
    @media(max-width: 850px) {
        margin-top: 120px;
    }
`

const StrongWithUnderline = styled.strong`
    text-decoration: underline;
`

const NYDCA = () => {
    return <Main>
        <p>
            A Glossary of Common Debt Collection Terms 
            and other resources are available in different languages at 
            <a href="https://www.nyc.gov/dcwp"> www.nyc.gov/dcwp</a>.
        </p>
        <StrongWithUnderline>If you need language assistance:</StrongWithUnderline>
        <p>Some services are available in Spanish by trained call center agents or Interactive Voice Response at (866) 654–0020.  Third-party interpreter services in other languages are also available at that number. </p>
        <i>Algunos servicios están disponibles en Español a través de agentes capacitados del centro de atención telefónica o respuesta de voz interactiva llamando al (866) 654–0020. En ese número también se encuentran disponibles servicios de intérpretes externos para otros idiomas.</i>
    </Main>;
}

export default NYDCA;