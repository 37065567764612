import React from 'react'
import styled from 'styled-components';
import useWindowSize from '../../Hooks/useWindowSize'
import { useApiContext } from '../../Hooks/useApiContext';

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -226px;
    background-image:url('/assets/my-home-toolkit-title.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 202px;
    justify-content: center;
    ${({ isTablet }) => isTablet ? `background-size:contain;` : ''}
   @media(max-width:600px) {
    margin-top: -108px;
    height:115px;
  }
`;

const Title = styled.h2`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  font-size: 80px;
  @media(max-width:1000px) {
    font-size:60px;
    line-height:60px;
  }
  @media(max-width:600px) {
    font-size:40px;
    line-height:50px;
  }
  `

const SubTitle = styled.h3`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Lato;

  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  font-size: 30px;
  @media(max-width:1000px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media(max-width:600px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const ImportantDisclosure = styled.section`
  border-radius: 20px;
  border: 1px solid var(--Light-Blue-2, #C5DCFB);
  background: var(--Light-Blue, #F8FBFF);
  color: var(--Black, #000);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    padding:30px;
    max-width: 1060px;
    margin-bottom: 40px;
`

const BoldText = styled.p`
  font-weight:bold;
  display:inline-block;
  margin:0;
`

const Main = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction: column;
  margin-top: 70px;
  @media(max-width:600px) {
    margin-top: 40px;
  }
`
const TileList = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  max-width: 1080px;
  margin-left: 20px;
`
const Tile = styled.div`
      width:520px;
      height: 417px;
      border-radius: 20px;
      background: #FFF;
      margin-right: 20px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
      margin-bottom: 20px;
      @media(max-width:1100px) {
        width:402px;
        margin-right: 10px;
      }
      @media(max-width:844px) {
        width:521px;
      }  

      @media(max-width:600px) {
        width:390px;
      }  
      .tile-image {
        ${({ titleImage }) => `background-image:url(${titleImage})`};
        width: 100%;
        height: 178px;
        background-size: contain;
        background-repeat: no-repeat;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
`

const TileTitle = styled.h2`
    color: var(--Text-Normal, #111);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`

const InformationText = styled.p`
    color: var(--Text-Normal, #111);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    margin-top: 20px;
`

const TileBody = styled.div`
  padding:20px;
  height: calc(100% - 178px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`

const ReadMoreButton = styled.a`
    border-radius: 10px;
    background: #475468;
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    &:hover {
      color: #FFF;
      text-decoration: none;
    }
`

const TileFooter = styled.div`
    display: flex;
    justify-content: space-between;
`

const PoweredBy = styled.div`
    color: var(--Text-Subtle, #767676);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    .powered-by-image {
        ${({ poweredByImg }) => `background-image:url(${poweredByImg})`};
        width: 120px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
    }
`


const TILE_DATA = [
  {
    titleImage:'/assets/property-insurance-background.png',
    tileTitle: 'Property Insurance That Makes Sense',
    subTitle: 'Shop 40+ A-rated carriers. Start your quotes now.',
    poweredByImg:'/assets/matic-logo.png',
    readMoreLink: 'https://rmsd.matic.com/home',
    message:'matic',
  },
  {
    titleImage:'/assets/exingquish-your-todo-list.png',
    tileTitle: 'Extinguish your to do list.',
    subTitle: 'Hire a firefighter for your next home project.',
    poweredByImg:'/assets/helpful-heroes-logo.png',
    readMoreLink: 'https://hidrent.com/about/',
    message:'helpfulheroes'
  },
  {
    titleImage:'/assets/home-repairs-background.png',
    tileTitle: 'Convenient Home Repairs, Maintenance & Remodeling',
    subTitle: `MCS offers comprehensive home repair, maintenance, and remodeling services 
                to make sure your home looks great, is safe, and functions at its best.`,
    poweredByImg:'/assets/mcs-logo.png',
    readMoreLink: 'https://yourhome.mcs360.com/',
    message:'msc'
  },
  {
    titleImage:'/assets/need-to-sell-your-home-background.png',
    tileTitle: 'Need to sell your home?',
    subTitle: 'Xome® Xpert Seller enables homeowners and heirs to sell properties by connecting you to an extensive buyer network through their national real estate platform.',
    poweredByImg:'/assets/xome-logo.png',
    readMoreLink: 'https://www.xome.com/xpert-seller/RMSD',
    message:'xome'
  }
]


const HomeToolkit = () => {
  const { isTablet } = useWindowSize();
  const { loan } = useApiContext()
  const isAuthenticated = Boolean(localStorage.getItem('ajs_user_traits'));
  const servicedAs = loan.data?.data?.loan?.loanData?.legalEntity?.legalEntity[0]?.servicer[0]?.servicedAs;
  return (
    <>
      <Header isTablet={isTablet}>
      <Title isTablet={isTablet} >My Home Toolkit</Title>
        <SubTitle isTablet={isTablet}>
          Connecting You With Helpful Service Providers
        </SubTitle>

      </Header>

      <Main>
        <TileList>
        {TILE_DATA.map(({titleImage, tileTitle, subTitle, poweredByImg, readMoreLink, message}) => 
         <Tile titleImage={titleImage}>
          <div class="tile-image"></div>
          <TileBody>
            <div class="title-section">
              <TileTitle>{tileTitle}</TileTitle>
              <InformationText>{subTitle}</InformationText>
            </div>
            <TileFooter>
              <PoweredBy poweredByImg={poweredByImg}>
                Powered By:
                <div class="powered-by-image"></div>

              </PoweredBy>
              <ReadMoreButton href={readMoreLink} data-segment-message={message} >Read More</ReadMoreButton>
            </TileFooter>
          </TileBody>
        </Tile>
        )}
        </TileList>
        <ImportantDisclosure>
        <BoldText>Important Disclosure:</BoldText> These resources are being provided to assist in identifying
        potential service providers.  {isAuthenticated ? servicedAs : 'The Reverse Mortgage Servicing Department'} is not responsible for the content or services provided by the listed entities, nor are they endorsed, published by, 
        or otherwise associated with  {isAuthenticated ? servicedAs : 'the Reverse Mortgage Servicing Department'}. 
        The logos and other information belong to the entities listed and are subject to all applicable copyright, trademark, 
        and other proprietary rights of those entities.
        </ImportantDisclosure>
      </Main>
    </>
  )
}

export default HomeToolkit
