import * as React from "react";
import styled from "styled-components";
import GreaterThanIcon from "../../assets/greater-than-icon";

const StyledCommonQuestionItem = styled.a`
  border-radius: 20px;
  box-shadow: 0px 1px 3px 0px rgba(92, 114, 143, 0.2);
  background-color: var(--White, #fff);
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 16px;
  color: var(--Black, #000);
  font-weight: 400;
  line-height: 24px;
  padding: 10px 21px;
  margin-top: 20px;
  flex-wrap: nowrap;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    padding-right: 20px;
    max-width: 520px;
  }
`;

const Message = styled.div`
  font-family: Lato, sans-serif;
  // align-self: end;
  // margin-top: 21px;
  flex-grow: 1;
  flex-basis: auto;
  align-content: center;
`;

const Separator = styled.div`
  background-color: var(--Light-Blue-2, #c5dcfb);
  width: 1px;
  height: 68px;
`;

const Image = styled.div`
  aspect-ratio: 0.57;
  object-fit: auto;
  object-position: center;
  width: 8px;
  fill: var(--Primary, #074ee8);
  margin: auto 0;
`;

export default function CommonQuestionItem({ question }) {
  return (
    <StyledCommonQuestionItem href={question.href}>
      <Message>
        {question.text}
      </Message>
      <Separator />
      <Image>
        <GreaterThanIcon />
      </Image>
    </StyledCommonQuestionItem>
  );
}