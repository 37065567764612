import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const GreaterThanIcon = ({ color = 'primary' }) => {
    return (
        <StyledSVG
            color={color}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.65639 6.58557L7.657 6.58496L9.07121 7.99917L2.00014 15.0702L0.58593 13.656L6.24218 7.99978L0.585174 2.34277L1.99939 0.92856L7.65639 6.58557Z" fill="#074EE8" />
        </StyledSVG>


    )
}

export default GreaterThanIcon