import { useState, useEffect } from 'react'

export const useApi = (api) => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: true,
  })

  const refresh = async () => {
    const datums = await api({ refresh: true })
    setData({ ...data, data: datums })
  }

  useEffect(() => {
    let isMounted = true
    const saturateData = async () => {
      try {
        const datums = await api()
        if (isMounted)
          setData({
            data: datums,
            loading: false,
            error: false,
          })
      } catch (ex) {
        if (isMounted) setData({ error: ex })
      }
    }
    saturateData()
    return () => (isMounted = false)
  }, [api])

  return { data, refresh }
}
