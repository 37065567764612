import React from 'react'
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 24px;
    color: var(--Dark-Grey, #475468);
    font-weight: 700;
    padding: 0 20px;
    flex-wrap: nowrap;
    line-height: 44px;
  
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      flex-wrap: wrap;
      font-size: 40px;
      line-height: 110%;
    }
  `;

const LeftBar = styled.div`
    background: linear-gradient(90deg, #f8fbff 0%, #9baac0 100%);
    align-self: stretch;
    display: flex;
    width: 24px;
    max-width: 100%;
    height: 1px;
    flex-direction: column;
    margin: auto 0;

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      width: 100px;
    }
  `;

const Title = styled.div`
    font-family: Lato, sans-serif;
    font-size: 18px;
    // align-self: stretch;
    // flex-grow: 1;
    // flex-basis: auto;

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      font-size: 24px;
    }
  `;

const RightBar = styled.div`
    background: linear-gradient(90deg, #9baac0 0%, #f8fbff 100%);
    align-self: stretch;
    display: flex;
    width: 24px;
    max-width: 100%;
    height: 1px;
    flex-direction: column;
    margin: auto 0;

    @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
      width: 100px;
    }
  `;

const LandingPageHeading = ({ title }) => {
  return (
    <Container>
      <LeftBar />
      <Title className='heading-title'>{title}</Title>
      <RightBar />
    </Container>
  );
}

export default LandingPageHeading;