import { Cache } from 'aws-amplify'

export const getItemOrUpdate = async (
  key,
  updaterIfNotInCache,
  refresh = false,
) => {
  let item = getItem(key)
  if (refresh || !getItem(key)) {
    item = await updaterIfNotInCache()
    setItem(key, item)
    if (key.includes('GET_LOAN_KEY'))
      sessionStorage.setItem('loanId', JSON.stringify(item.data.loan.loanData.loanId))
  }

  return item
}

export const getItem = (key) => Cache.getItem(key)

export const setItem = (key, value) => {
  Cache.configure({ defaultTTL: 1000 * 60 * 60 })
  Cache.setItem(key, value)
}

export const clear = () => Cache.clear()

export const removeEntry = (key) => Cache.removeItem(key)
