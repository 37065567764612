import React from 'react'

const SiteDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="228"
      height="196"
      viewBox="0 0 228 196"
      fill="none"
    >
      <path
        fill="url(#paint0_linear)"
        d="M181.789 52.696c-10.553-4.113-19.468-11.618-24.92-21.545-9.169-16.693-26.91-28.009-47.302-28.009-21.165 0-39.475 12.191-48.306 29.934a49.697 49.697 0 01-25.868 23.94C18.762 63.729 4.799 79.07.368 103.054a21.948 21.948 0 00-.007 7.969c6.88 37.216 36.694 53.631 63.829 49.278 15.196-2.437 30.58 2.632 41.844 13.12 22.904 21.327 59.62 28.013 95.263 5.152 4.176-2.678 9.778-8.298 12.453-12.475 30.712-47.947 7.962-97.842-31.961-113.402z"
      ></path>
      <path
        fill="#00377A"
        d="M196.383 25.185h-53.091a36.249 36.249 0 00-7.409-7.63c-2.947-2.25-7.192-.193-7.192 3.515v4.115H99.309V21.07c0-3.7-4.238-5.77-7.18-3.525a36.264 36.264 0 00-7.422 7.639h-53.09a6.682 6.682 0 00-6.68 6.68v113.22a6.683 6.683 0 006.68 6.68h164.766a6.682 6.682 0 006.679-6.68V31.865a6.682 6.682 0 00-6.679-6.68z"
        opacity="0.1"
      ></path>
      <path
        fill="#fff"
        d="M139.254 193.493H88.746l10.1-40.035h30.307l10.101 40.035z"
      ></path>
      <path
        fill="#fff"
        d="M203.062 41.217v113.221a6.682 6.682 0 01-6.679 6.68H31.617a6.683 6.683 0 01-6.68-6.68V41.218a6.682 6.682 0 016.68-6.68h164.766a6.682 6.682 0 016.679 6.68z"
      ></path>
      <path
        fill="#588DDC"
        d="M203.062 75.516V41.217a6.682 6.682 0 00-6.679-6.68H31.617a6.682 6.682 0 00-6.68 6.68v34.299h178.125z"
      ></path>
      <path
        fill="#fff"
        d="M101.309 76.386h25.382v62.291h-25.382v-62.29zM203.062 55.99V41.218a6.68 6.68 0 00-6.679-6.68H31.617a6.68 6.68 0 00-6.68 6.68v14.771h178.125zM24.937 138.677v15.76a6.68 6.68 0 006.68 6.68h164.766a6.68 6.68 0 006.679-6.68v-15.76H24.937z"
      ></path>
      <path
        fill="#fff"
        d="M150.07 55.582c0 19.922-16.146 36.07-36.069 36.07-19.934 0-36.326-16.613-36.069-36.545.149-11.502 5.683-21.71 14.197-28.21 2.941-2.244 7.18-.175 7.18 3.525v15.362a4.453 4.453 0 004.453 4.454h20.475a4.453 4.453 0 004.453-4.454V30.421c0-3.708 4.246-5.765 7.192-3.514 8.625 6.59 14.188 16.984 14.188 28.675z"
      ></path>
      <path
        fill="#00377A"
        d="M41.896 47.523c1.23 0 2.226-.997 2.226-2.227v-.065a2.226 2.226 0 10-4.453 0v.065c0 1.23.997 2.227 2.227 2.227zm8.606 0c1.23 0 2.226-.997 2.226-2.227v-.065a2.226 2.226 0 10-4.453 0v.065c0 1.23.997 2.227 2.227 2.227zm8.605 0c1.23 0 2.226-.997 2.226-2.227v-.065a2.226 2.226 0 10-4.453 0v.065c0 1.23.997 2.227 2.227 2.227z"
      ></path>
      <path
        fill="#00377A"
        d="M196.383 32.311h-51.945a38.739 38.739 0 00-7.203-7.173 6.688 6.688 0 00-7.064-.692 6.624 6.624 0 00-3.706 5.975v1.89h-14.896a2.227 2.227 0 100 4.453h14.896v9.02a2.23 2.23 0 01-2.227 2.227h-20.475a2.23 2.23 0 01-2.227-2.226V30.423a6.628 6.628 0 00-3.71-5.98 6.668 6.668 0 00-7.047.685 38.66 38.66 0 00-7.21 7.183H31.617c-4.91 0-8.906 3.995-8.906 8.906v113.22c0 4.911 3.995 8.906 8.906 8.906h62.44l-7.045 27.922h-6.826a2.226 2.226 0 100 4.453h67.628a2.226 2.226 0 000-4.453h-6.827l-7.044-27.922h62.44c4.911 0 8.906-3.995 8.906-8.906V41.217c0-4.911-3.995-8.906-8.906-8.906zM27.164 136.451V58.216h48.64c.627 9.075 4.465 17.577 11.012 24.207 3.587 3.634 7.748 6.46 12.266 8.398v45.63H27.164zm76.371-44.049a37.892 37.892 0 0010.466 1.477c3.628 0 7.135-.519 10.464-1.466v33.999a2.226 2.226 0 104.453 0v-35.56c12.958-5.5 22.274-17.945 23.278-32.636h48.64v78.235h-97.301V92.402zm92.848-55.638a4.458 4.458 0 014.453 4.453v12.546h-48.585c-.281-6.071-1.962-11.863-4.864-16.999h48.996zM93.481 28.668c.41-.314.885-.473 1.366-.473.339 0 .68.079 1.004.239.37.183 1.232.743 1.232 1.988v15.362a6.687 6.687 0 006.679 6.68h20.476a6.687 6.687 0 006.679-6.68V30.421c0-1.243.859-1.802 1.228-1.984.787-.389 1.679-.3 2.386.239 8.461 6.465 13.313 16.272 13.313 26.906 0 18.66-15.182 33.843-33.843 33.843-9.037 0-17.566-3.597-24.016-10.13-6.453-6.536-9.943-15.116-9.826-24.159.135-10.461 4.99-20.108 13.322-26.468zm-61.864 8.096h49.004a38.087 38.087 0 00-4.873 16.999H27.164V41.217a4.458 4.458 0 014.453-4.453zm104.778 154.501h-44.79l7.043-27.922h30.702l7.045 27.922zm59.988-32.375H31.617a4.458 4.458 0 01-4.453-4.453v-13.533h173.672v13.533a4.458 4.458 0 01-4.453 4.453z"
      ></path>
      <path
        fill="#00377A"
        d="M71.605 93.076h-6.67a2.282 2.282 0 010-4.563h6.67a2.281 2.281 0 010 4.563zm11.528 0h-2.93a2.282 2.282 0 010-4.563h2.93a2.281 2.281 0 010 4.563zm0 9.048H64.935a2.282 2.282 0 010-4.563h18.198a2.282 2.282 0 010 4.563zm-38.823-2.115c-.59 0-1.157-.234-1.574-.652L39.63 96.25a2.226 2.226 0 113.148-3.149l1.533 1.533 5.468-5.468a2.226 2.226 0 013.149 3.148l-7.043 7.043a2.226 2.226 0 01-1.574.652zm27.295 17.493h-6.67a2.282 2.282 0 010-4.563h6.67a2.282 2.282 0 010 4.563zm11.528 0h-2.93a2.282 2.282 0 010-4.563h2.93a2.282 2.282 0 010 4.563zm0 9.048H64.935a2.281 2.281 0 110-4.562h18.198a2.281 2.281 0 110 4.562zm-38.823-2.114c-.59 0-1.157-.235-1.574-.652l-3.107-3.108a2.226 2.226 0 113.148-3.149l1.533 1.533 5.468-5.468a2.227 2.227 0 013.149 3.149l-7.043 7.043a2.226 2.226 0 01-1.574.652zm130.905-31.36h-6.671a2.282 2.282 0 110-4.563h6.671a2.281 2.281 0 010 4.563zm11.527 0h-2.929a2.282 2.282 0 010-4.563h2.929a2.282 2.282 0 110 4.563zm0 9.048h-18.198a2.282 2.282 0 010-4.563h18.198a2.282 2.282 0 110 4.563zm-38.822-2.115a2.225 2.225 0 01-1.574-.652l-3.108-3.107a2.227 2.227 0 013.149-3.149l1.532 1.533 5.469-5.468a2.226 2.226 0 113.148 3.148l-7.042 7.043a2.23 2.23 0 01-1.574.652zm27.295 17.493h-6.671a2.282 2.282 0 010-4.563h6.671a2.282 2.282 0 010 4.563zm11.527 0h-2.929a2.282 2.282 0 010-4.563h2.929a2.282 2.282 0 010 4.563zm0 9.048h-18.198a2.28 2.28 0 110-4.562h18.198a2.282 2.282 0 110 4.562zm-38.822-2.114a2.226 2.226 0 01-1.574-.652l-3.108-3.108a2.228 2.228 0 013.149-3.149l1.532 1.533 5.469-5.468a2.227 2.227 0 013.148 3.149l-7.042 7.043a2.23 2.23 0 01-1.574.652z"
      ></path>
      <path
        fill="#588DDC"
        d="M163.075 190.31a2.225 2.225 0 01-2.226-2.226 2.682 2.682 0 00-2.679-2.679 2.227 2.227 0 110-4.454 2.682 2.682 0 002.679-2.679 2.226 2.226 0 114.453 0 2.681 2.681 0 002.679 2.679 2.226 2.226 0 010 4.454 2.682 2.682 0 00-2.679 2.679 2.227 2.227 0 01-2.227 2.226zM152.72 23.91a2.227 2.227 0 01-2.227-2.227 2.682 2.682 0 00-2.679-2.679 2.227 2.227 0 110-4.453 2.682 2.682 0 002.679-2.679 2.227 2.227 0 114.453 0 2.682 2.682 0 002.679 2.68 2.227 2.227 0 110 4.452 2.682 2.682 0 00-2.679 2.68c0 1.229-.997 2.226-2.226 2.226zm-30.934-9.363a2.227 2.227 0 01-2.227-2.227 2.682 2.682 0 00-2.679-2.679 2.226 2.226 0 110-4.453 2.682 2.682 0 002.679-2.68 2.227 2.227 0 014.453 0 2.682 2.682 0 002.679 2.68 2.227 2.227 0 110 4.453 2.682 2.682 0 00-2.679 2.679c0 1.23-.996 2.227-2.226 2.227zM73.448 182.842a2.227 2.227 0 01-2.226-2.227 2.682 2.682 0 00-2.679-2.679 2.227 2.227 0 110-4.453 2.682 2.682 0 002.679-2.679 2.227 2.227 0 114.453 0 2.682 2.682 0 002.68 2.679 2.226 2.226 0 110 4.453 2.682 2.682 0 00-2.68 2.679c0 1.23-.997 2.227-2.226 2.227z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="13.297"
          x2="235.119"
          y1="54.047"
          y2="143.741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3ECF5"></stop>
          <stop offset="1" stopColor="#B4D9FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SiteDownIcon
