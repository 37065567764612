import React, { useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'
import styled from 'styled-components'

import Grid from '../Global/Grid'
import Theme from '../Global/Theme'
import StyleGuide from '../Global/StyleGuide'
import Button from '../Common/button'
import useWindowSize from '../Hooks/useWindowSize'

import UnsupportedBrowserIcon from '../assets/unsupported-browser-icon'
import Chrome from '../assets/chrome.jpg'
import Safari from '../assets/safari.jpg'
import FireFox from '../assets/firefox.jpg'
import Edge from '../assets/edge.jpg'

const StyledUnsupportedBrowserPage = styled.div`
  padding-top: 90px;
  text-align: center;

  svg {
    min-height: 116px;
    min-width: 150px;
  }

  .heading {
    margin: 58px 0 0 0;
  }

  .sub_heading {
    margin: 24px 0 28px 0;
  }

  .options {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .browser {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
      display: flex;
      padding: 16px;
      min-width: 260px;
      margin: 0 12px;
      border: 1px solid ${({ theme }) => theme.colors.black34};
      border-radius: 4px;

      img {
        width: 60px;
        height: 60px;
      }

      .text {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: start;

        h6 {
          margin: 0 0 12px 0;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    .options {
      flex-direction: column;
      margin: 0;

      .browser {
        margin: 8px 0;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    padding-top: 70px;

    svg {
      min-height: 60px;
      min-width: 80px;
      height: 60px;
      width: 80px;
    }

    .heading {
      margin: 38px 0 0 0;
    }

    .sub_heading {
      margin: 16px 0 24px 0;
    }
  }
`

const parsedUserAgent = new UAParser()
const browser = parsedUserAgent.getBrowser().name

const UnsupportedBrowserPage = ({ children }) => {
  const [isInternetExplorer, setIsInternetExplorer] = useState(false)

  useEffect(() => {
    if (browser.includes('IE')) setIsInternetExplorer(true)
  }, [setIsInternetExplorer])

  const { isXSmall } = useWindowSize()

  return isInternetExplorer ? (
    <Theme>
      <StyleGuide>
        <StyledUnsupportedBrowserPage>
          <Grid>
            <UnsupportedBrowserIcon />
            <h4
              className={` ${
                isXSmall ? 'heading-6' : 'heading-4'
              } heading bold`}
            >
              Internet Explorer is no longer supported.
            </h4>
            <h5
              className={`${
                isXSmall ? 'subtitle-2' : 'subtitle'
              } sub_heading`}
              data-gridcolumnlayout={{
                sm: '1/9',
                lg: '2/12',
                xl: '5/11',
              }}
            >
              Microsoft is no longer updating the Internet Explorer
              browser. To get the best experience using our site,
              please install a different browser. Here are a few
              options:
            </h5>
            <div
              className="options"
              data-gridcolumnlayout={{ sm: '2/8', md: '4/10' }}
            >
              <a
                className="browser"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/chrome/"
              >
                <img src={Chrome} alt="Chrome" />
                <div className="text">
                  <h6 className="subtitle bold">Google Chrome</h6>
                  <Button
                    text="Download"
                    UID="unsupported_browser"
                    textButton
                  />
                </div>
              </a>
              <a
                className="browser"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.mozilla.org/en-US/firefox/new/"
              >
                <img src={FireFox} alt="mozilla firefox" />
                <div className="text">
                  <h6 className="subtitle bold">Mozilla Firefox</h6>
                  <Button
                    text="Download"
                    UID="unsupported_browser"
                    textButton
                  />
                </div>
              </a>
            </div>
            <div
              className="options"
              data-gridcolumnlayout={{ sm: '2/8', md: '4/10' }}
            >
              <a
                className="browser"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.apple.com/downloads/safari"
              >
                <img src={Safari} alt="safari" />
                <div className="text">
                  <h6 className="subtitle bold">Safari</h6>
                  <Button
                    text="Download"
                    UID="unsupported_browser"
                    textButton
                  />
                </div>
              </a>
              <a
                className="browser"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.microsoft.com/en-us/edge"
              >
                <img src={Edge} alt="microsoft edge" />
                <div className="text">
                  <h6 className="subtitle bold">Microsoft Edge</h6>
                  <Button
                    text="Download"
                    UID="unsupported_browser"
                    textButton
                  />
                </div>
              </a>
            </div>
          </Grid>
        </StyledUnsupportedBrowserPage>
      </StyleGuide>
    </Theme>
  ) : (
    <>{children}</>
  )
}

export default UnsupportedBrowserPage
