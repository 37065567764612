import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Button from '../Common/button'
import { buildTestID } from '../Utils/utils'

const StyledLiborModal = styled(Modal)`
  .header {
    margin: 32px 32px 20px 32px;
    text-align: left;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .body {
    margin: 0 32px 20px 32px;
  }

  .body-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.black87};
  }

  .buttons {
    .modal-close {
        justify-content: right;
        margin-right: 15px;
        margin-bottom: 15px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    
  }
`

const LiborModal = ({ onHide, show, linkTo }) => {
    return (
        <StyledLiborModal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="header">
                <div className="buttons">
                    <Button
                        className='modal-close'
                        onClick={onHide}
                        textButton
                        text="CLOSE"
                        UID="registration_modal"
                    />
                </div>
            </div>
            <div className="body">
                <h5 className="title text-center mb-3">Discontinuation of LIBOR Index Rate: Fact Sheet</h5>
                <p className="body-text my-3">
                    <strong>Discontinuation of LIBOR Index Rate</strong>: If you have an Adjustable-Rate Mortgage loan with a note rate calculated using a LIBOR (London Interbank Offered Rate) index rate, this LIBOR Index Rate is being discontinued as of June 30, 2023 and will be replaced on your next rate change date and going forward, as defined in your loan note. The calculation of your new note rate will use the spread adjusted SOFR (Secured Overnight Financing Rate) index, published by Refinitiv Benchmark Services (UK) Limited, after June 30, 2023.
                </p>
                <h5 className="my-3"><strong>Why is the LIBOR Index Rate being discontinued?</strong></h5>
                <ul>
                    <li>In July of 2017, the United Kingdom’s Financial Conduct Authority (FCA), the financial regulator of LIBOR, announced that it would no longer persuade or compel contributing banks to submit rates used to calculate LIBOR after December 31, 2021. On March 5, 2021, the Intercontinental Exchange (ICE) Benchmark Administration Limited (IBA) published the feedback it received to its December 2020 report on the potential cessation of LIBOR, and announced it intended to cease publication on June 30, 2023. The Alternative Reference Rate Committee (ARRC) is a group of private-market participants convened by the Federal Reserve Board and the Federal Reserve Bank of New York to help ensure a successful transition from U.S. dollar (USD) LIBOR to a more robust reference rate. In its Best Practices last updated on April 21, 2023, the ARRC recommended the use of the Refinitiv USD IBOR Consumer Cash Fallback based on the Chicago Mercantile Exchange (CME) Group’s Term Secured Overnight Financing Rate (SOFR) rates (CME Term SOFR) plus the applicable spread adjustment for legacy LIBOR ARMs and the use of SOFR averages for new originations.</li>
                    <li>On October 19, 2022, HUD published a proposed rule in the Federal Register (FR). After reviewing and considering the comments received, HUD published the Final Rule in the FR on March 1, 2023, to revise its regulations in parts 203 and 206. On May 2, 2023, HUD published Mortgagee Letter 2023-09 which implements the Final Rule and establishes the All-In CME Term SOFR, which includes the applicable spread adjustment, as published by Refinitiv Benchmark Services (UK) Limited, as the Secretary-approved replacement Index. FHA announced in Mortgagee Letter 2023-09 the Transition of existing LIBOR-indexed Adjustable Rate HECMs to the Secretary-approved Replacement Index to the new spread adjusted SOFR (Secured Overnight Financing Rate) index to occur on the next interest rate adjustment date on or after the first London banking day after June 30, 2023.</li>
                </ul>
                <h5 className="my-3"><strong>What allows my lender to change my index?</strong></h5>
                <ul>
                    <li>The terms of your note state that if the existing index is discontinued, that index rate will be replaced with another index as prescribed by FHA or your lender.</li>
                </ul>
                <h5 className="my-3"><strong>Do I need to do anything about the index rate changing, like refinance my loan?</strong></h5>
                <ul>
                    <li>You are not required to take any action, nor are you required to refinance your loan. Because the index used to calculate your note rate will change, in accordance with the terms of your mortgage note, this change will occur without disruption in the servicing of your loan.</li>
                </ul>
                <h5 className="my-3"><strong>Will this affect my ability to access funds from my loan, if I have any available to borrow?</strong></h5>
                <ul>
                    <li>No. The change in index used to calculate your note rate does not impact your ability to access any funds available. If you have funds available to borrow, you may continue to do so.</li>
                </ul>
                <h5 className="my-3"><strong>My Adjustable-Rate Mortgage loan uses the CMT (Constant Maturity Treasury) index. Does this change affect my rate?</strong></h5>
                <ul>
                    <li>No. If your note rate uses the CMT (Constant Maturity Treasury) index, your mortgage will not be affected by this change.</li>
                </ul>
                <h5 className="my-3"><strong>Are there other resources I can reference regarding this change?</strong></h5>
                <ul>
                    <li>Yes, for more information please review these CFPB articles <a href="https://www.consumerfinance.gov/about-us/blog/adjustable-rate-loans-are-changing-widely-used-interest-rate-index-expires-in-june/" data-testid={buildTestID('widely-used', '/', 'libor')}>Widely Used Interest Rate Index Expires June 2023</a> and <a href="https://www.consumerfinance.gov/about-us/newsroom/cfpb-issues-rule-to-facilitate-orderly-wind-down-of-libor/" data-testid={buildTestID('cfpb-issues-rules', '/', 'libor')}>CFPB Issues Rule to Facilitate Orderly Wind Down of LIBOR</a> and the guidance from FHA/HUD here: <a href="https://www.hud.gov/sites/dfiles/SFH/documents/SFH_FHA_INFO_2023-36.pdf" data-testid={buildTestID('fha-info-2023-36', '/', 'libor')}>FHA_INFO_2023-36</a></li>
                </ul>
            </div>
            <div className="buttons">
                <Button
                    className='modal-close'
                    onClick={onHide}
                    textButton
                    text="CLOSE"
                    UID="registration_modal"
                />
            </div>
        </StyledLiborModal>
    )
}

LiborModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
}

export default LiborModal
