import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { trackUserSignedOut } from '../../Global/Analytics'

const TimeoutModal = ({
  showModal,
  handleClose,
  handleLogout,
  timeoutInSeconds,
}) => {
  const [timeElapsed, setTimeElapsed] = useState(0)
  const [modalStatus, setModalStatus] = useState("")

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeElapsed((prevTime) => prevTime + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const onClose = (e) => {
    setModalStatus("close modal button click")
    setTimeout(() => {
      handleClose(e)
    }, 1000);
  }

  const handleStayClick = (e) => {
    setModalStatus("stay signed in click")
    setTimeout(() => {
      handleClose(e)
    }, 1000);
  }

  const handleSignOutClick = (e) => {
    setModalStatus("sign out click")
    setTimeout(() => {
      trackUserSignedOut()
      handleLogout()
    }, 1000)
  }

  if (timeElapsed >= timeoutInSeconds) handleLogout()
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>You Have Been Idle!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span role="timer" aria-live="off">
          Your session will expire in {timeoutInSeconds - timeElapsed}{' '}
          seconds. What would you like to do?
        </span>
        {(timeoutInSeconds - timeElapsed) % 20 === 0 ? (
          <div id="alert" role="alert" aria-live="assertive" style={{ "position": "absolute", "width": 0, "height": 0, "clip": "rect(0,0,0,0)" }}>Your session will expire in {timeoutInSeconds - timeElapsed}{' '}
            seconds. What would you like to do?</div>
        ) : ""}
        {modalStatus !== "" && <p role="alert" aria-live="assertive" className='sr-only'>{modalStatus}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleSignOutClick}
          UID="time_out"
        >
          Sign out
        </Button>
        <Button
          variant="primary"
          onClick={handleStayClick}
          UID="time_out"
        >
          Stay signed in
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TimeoutModal
