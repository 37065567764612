import React from "react";
import styled from "styled-components";

const Title = styled.h1`
    text-align: center;
    margin-top: 50px;
    font-size: 2.5rem;
`

const Main = styled.div`
    margin: 20px;
    @media(max-width: 850px) {
        margin-top: 120px;
    }
    p {
     margin: 0;
    }
    ol > li {
     font-weight: bold;
    }
    ul > li {
     font-weight: normal;
    }
    & > a {
     width: 100%;
     display: block;
     padding: 20px;
     text-align: center;
    }
`

const StrongWithUnderline = styled.strong`
    text-decoration: underline;
`

const SquareBulletList = styled.ul`
    list-style-type:square;
`

const InformationText = styled.p`
    margin-bottom: 0;
`
const ForeclosureAvoidance = () => {

    return <Main>
        <Title>Foreclosure Avoidance</Title>
        <InformationText>
            For Borrowers or Estate Representatives interested in avoiding foreclosure, the following Loss
            Mitigation options* may be available. Note: All forms, packages and applications referenced
            below may be obtained on our website below:
        </InformationText>
        <a href="https://www.reversedepartment.com/common-forms">https://www.reversedepartment.com/common-forms</a>
        <ol>
            <li>Pay the Loan Balance in Full using Certified Funds (i.e., wire transfer or Bank Cashier’s
                Check)
                <ul>
                    <li>Use personal funds or life insurance proceeds.</li>
                    <li>Refinance the existing loan.</li>
                    <li>Sell the property and pay off the loan
                        <SquareBulletList>
                            <li>A Short Sale may be available and requires an application. The Short Sale
                            Instruction Package is located on our website.</li>
                        </SquareBulletList>
                    </li>
                </ul>
            </li>
            <li>
          
                Establish a Repayment Plan to repay Property Charges advanced on your behalf
                <ul>
                    <li>Borrowers may be eligible to establish a repayment plan for a Property Charge Default
                    (Taxes, Insurance and or HOA dues/assessments).</li>
                    <li>Complete the <strong>Repayment Plan Review Request</strong> available on our website</li>
                    <li>A Financial Budget Worksheet (included in the above document) is required to be completed
                    at the time of your discussion with the servicing representative to determine eligibility.</li>
                </ul>
            </li>
            <li>
                <strong>Certify Occupancy</strong>
                <ul>
                    <li>
                        If the property is the primary residence of at least one borrower, complete the required
                        Annual Occupancy Certification by calling the phone number on the monthly
                        statement, logging into your online account at <a href="https://reversedepartment.com">reversedepartment.com</a>, or complete the
                        form on our website.
                    </li>
                </ul>
            </li>
            <li>
                <strong>Complete Required Property Repairs</strong>
                <ul>
                    <li>
                        Complete all repairs identified at the time of your loan closing (in the Repair Rider to
                        your Loan Agreement); or
                    </li>
                    <li>Complete other repairs needed as a result of an insurance claim.</li>
                    <li>Submit proof that the repairs have been completed</li>
                    <li>Repair forms are available on our website.</li>
                </ul>
            </li>
            <li>
                <strong>Ensure at Least one Borrower Always Remains on Title</strong>
                <ul>
                    <li>
                        Borrowers may add persons to the title of the home after the loan closing, but at least one
                        borrower must always remain on title to avoid a maturity event triggering the loan to be
                        called due and payable
                    </li>
                    <li>
                        Always discuss a change of title with your legal counsel as adding persons to title may
                        limit the ability of those persons to purchase the property using a Short Sale after the
                        death of the last remaining borrower. In general, title conveyance must be post-death to
                        quality for a Short Sale.
                    </li>
                </ul>
            </li>
            <li>
                Consider completing a Deed-in-Lieu of Foreclosure (DIL)
                <ul>
                    <li>
                        A borrower, or a person with authority after the last remaining borrower’s death, may
                        complete a Deed-in-Lieu of Foreclosure Request form available on our website.
                    </li>
                    <li>
                        Requirements for a DIL are explained in the form mentioned above.
                    </li>
                </ul>
            </li>
            <li>
                Submit an At-Risk Extension Request for Extension of Time Due to Critical Circumstances
                <ul>
                    <li>Review qualifications and apply using the form on our website.</li>
                </ul>
            </li>
        </ol>
        <StrongWithUnderline>*For NY Residents:</StrongWithUnderline>
        <i>
            If the property is located in New York, please refer to the Loss Mitigation
            Application (NY Only) located on our website.
        </i>

        <p>
            <StrongWithUnderline>
                Free Counseling is Available:
            </StrongWithUnderline>
        </p>
        <p>
            <i>
                We encourage you to discuss your options with a HUD counselor, free of charge.
                <p>
                    You may obtain a list of HUD-approved counselors or counseling organizations in your area by calling the
                    HUD nationwide toll-free telephone number at (800) 569-4287 or search online at 
                    <a href="https://www.hud.gov/findacounselor"> www.hud.gov/findacounselor </a>
                </p>
            </i>
        </p>
        <strong>If you have any remaining questions, please consult the Common Questions on our website, or
        contact our Borrower Care Department, at:</strong>
        <ul>
            <li>
                Phone: (866) 654-0020
                <ul>
                    <li>Hours: 8AM-7PM (Monday –Thursday), 8AM-5PM (Friday), Eastern time</li>
                    <li>Closed Saturday, Sunday and major holidays.</li>
                </ul>
            </li>
            <li>
                Email: Borrower Care: <a href="mailto:BC@reversedepartment.com">BC@reversedepartment.com
            </a>
            </li>
        </ul>
    </Main>
}

export default ForeclosureAvoidance;