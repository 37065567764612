import dayjs from 'dayjs'

// break functions out into catigorized util files once having one becomes unwieldy

export const handleScrollIntoView = (
  event,
  scrollBehavior,
  block,
) => {
  // detecting if on IE (IE does not support scrollIntoView objects)
  if (window.document.documentMode) {
    event.scrollIntoView(true)
    let halfScreenHeight =
      block === 'start' ? 0 : window.innerHeight / 2
    let currentYLoc = window.pageYOffset
    window.scroll(0, currentYLoc - halfScreenHeight)
  } else {
    event.scrollIntoView({
      behavior: scrollBehavior,
      block: block ? block : 'center',
    })
  }
}

export const numberToMoney = (number) => {
  let numberWithCommas = Number.parseFloat(number)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  let decimals = numberWithCommas.split('.')[1]

  if (!decimals) {
    return `$${numberWithCommas}.00`
  } else if (decimals.length === 1) {
    return `$${numberWithCommas}0`
  } else {
    return `$${numberWithCommas}`
  }
}

export const buildTestID = (name, pathname, UID) => {
  // name_path_name_uid(optional)

  let testName = name.toLocaleLowerCase().split(' ').join('_')

  let testEndpoint = pathname.slice(1).split('-').join('_') || 'home'

  // accounts for testing on local scenerio
  if (pathname.includes('local')) {
    testEndpoint = 'local'
  }

  let testUID = UID ? UID : ''

  return `${testName}_${testEndpoint}_${testUID}`.replace(/_+$/, '')
}

export const formatDate = (dateWithDashes) => {
  return dayjs(
    new Date(dateWithDashes).toLocaleDateString('en-US', {
      timeZone: 'UTC',
    }),
  ).format('MM/DD/YY')
}

// converts every word in sentence to start with uppercase letter
export const toUpper = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}

export const formatPhoneNumber = (phoneNumber) => {
  const areaCode = phoneNumber.slice(0, 3)
  const centralOffice = phoneNumber.slice(4, 7)
  const lineNumber = phoneNumber.slice(8, 12)

  return `(${areaCode}) ${centralOffice}-${lineNumber}`
}

export const formatPhoneNumberNormalize = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return phoneNumberString;
}

export const isValidPhoneNumber = (value) => {
  if (!value) return false
  return Boolean(
    value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/),
  )
}

export const parseDate = (dateWithDashes) => {
  const dateParts = dateWithDashes.split('-')
  var year = dateParts[0]
  var month = parseInt(dateParts[1], 10) - 1
  var date = dateParts[2]
  return new Date(year, month, date)
}

export const FormatMoney = (value) => {
  let parsed = Number.parseFloat(value).toFixed(2).toString()

  return parsed === null
    ? '$'
    : `$${parsed.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

export const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

export const convertMoneyToWords = (amount) => {
  // Arrays to hold number words
  const ones = [
    '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
    'seventeen', 'eighteen', 'nineteen'
  ];
  const tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];

  // Function to convert a number less than 1000 to words
  function convertLessThanOneThousand(number) {
    let words = '';
    if (number % 100 < 20) {
      words = ones[number % 100];
      number = Math.floor(number / 100);
    } else {
      words = ones[number % 10];
      number = Math.floor(number / 10);
      words = tens[number % 10] + ' ' + words;
      number = Math.floor(number / 10);
    }
    if (number > 0) {
      words = ones[number] + ' hundred ' + words;
    }
    return words;
  }

  // Function to convert the entire number to words
  function convertWholeNumberToWords(num) {
    if (num === 0) {
      return 'zero';
    }

    let words = '';
    let billions = Math.floor(num / 1000000000);
    let millions = Math.floor((num % 1000000000) / 1000000);
    let thousands = Math.floor((num % 1000000) / 1000);
    let remainder = num % 1000;

    if (billions > 0) {
      words += convertLessThanOneThousand(billions) + ' billion ';
    }
    if (millions > 0) {
      words += convertLessThanOneThousand(millions) + ' million ';
    }
    if (thousands > 0) {
      words += convertLessThanOneThousand(thousands) + ' thousand ';
    }
    if (remainder > 0) {
      words += convertLessThanOneThousand(remainder);
    }

    return words.trim();
  }

  // Split the amount into dollars and cents
  const [dollars, cents] = amount.toString().replace("$", "").replace(",", "").split('.');

  let result = convertWholeNumberToWords(parseInt(dollars, 10)) + ' dollars';
  if (cents && parseInt(cents, 10) > 0) {
    result += ' and ' + convertWholeNumberToWords(parseInt(cents, 10)) + ' cents';
  }

  return result;
}

export const setCookie = (name, value, daysToExpire) => {
  const date = new Date()
  // Add one day to the current date
  date.setDate(date.getDate() + daysToExpire);
  const expires = "expires=" + date.toUTCString()
  document.cookie = name + "=" + value + "; " + expires
}

export const getCookie = (name) => {
  const cookies = document.cookie.split('; ')
  for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=')
      if (cookieName === name) {
          return decodeURIComponent(cookieValue)
      }
  }
  return null; // Cookie not found
}