import React from 'react'
import styled from 'styled-components'

const StyledSVG = styled.svg`
  path {
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`
const UnsupportedBrowserIcon = ({ className, color = 'black60' }) => {
  return (
    <StyledSVG
      color={color}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="116"
      viewBox="0 0 150 116"
    >
      <path
        fill="#5A5A5A"
        d="M144.873.729h-33.289a2.197 2.197 0 100 4.394h33.289c.404 0 .732.329.732.733v13.338H4.395V5.856c0-.404.328-.733.732-.733h96.343a2.197 2.197 0 000-4.394H5.127A5.133 5.133 0 000 5.856v104.289c0 2.827 2.3 5.127 5.127 5.127h116.996a2.197 2.197 0 000-4.395H5.127a.734.734 0 01-.732-.732V23.588h141.21v28.538a2.198 2.198 0 004.395 0V5.856c0-2.827-2.3-5.127-5.127-5.127z"
      ></path>
      <path
        fill="#5A5A5A"
        d="M147.803 60.043a2.198 2.198 0 00-2.198 2.197v47.905a.733.733 0 01-.732.732h-12.636a2.197 2.197 0 000 4.395h12.636c2.827 0 5.127-2.3 5.127-5.127V62.24a2.197 2.197 0 00-2.197-2.197zM13.896 9.961a2.197 2.197 0 000 4.395h.034a2.197 2.197 0 000-4.395h-.034zM20.53 9.961a2.197 2.197 0 000 4.395h.033a2.197 2.197 0 000-4.395h-.033zM27.164 9.961a2.197 2.197 0 000 4.395h.033a2.197 2.197 0 000-4.395h-.033zM136.104 14.356a2.197 2.197 0 000-4.395h-4.089a2.197 2.197 0 100 4.395h4.089zM98.46 53.458c0-12.936-10.524-23.46-23.46-23.46-12.936 0-23.46 10.524-23.46 23.46 0 12.936 10.524 23.46 23.46 23.46 12.936 0 23.46-10.524 23.46-23.46zM75 72.524c-10.513 0-19.066-8.553-19.066-19.066 0-10.513 8.553-19.066 19.066-19.066 10.513 0 19.066 8.553 19.066 19.066 0 10.513-8.553 19.066-19.066 19.066zM67.242 79.879a2.197 2.197 0 000 4.394h15.517a2.197 2.197 0 000-4.394H67.242zM96.17 96.964a2.197 2.197 0 00-2.197-2.197H39.055a2.197 2.197 0 000 4.394h54.918a2.197 2.197 0 002.197-2.197zM110.945 94.767h-8.052a2.197 2.197 0 000 4.394h8.052a2.198 2.198 0 100-4.394zM39.055 101.457a2.198 2.198 0 000 4.395h17.542a2.197 2.197 0 000-4.395H39.055zM64.931 105.852h46.014a2.198 2.198 0 100-4.395H64.931a2.198 2.198 0 000 4.395z"
      ></path>
      <path
        fill="#5A5A5A"
        d="M83.145 45.313a2.197 2.197 0 00-3.107 0L75 50.351l-5.038-5.038a2.197 2.197 0 10-3.107 3.107l5.038 5.038-5.038 5.037a2.197 2.197 0 103.107 3.108L75 56.565l5.038 5.038a2.19 2.19 0 001.553.644 2.197 2.197 0 001.554-3.75l-5.038-5.039 5.038-5.038a2.197 2.197 0 000-3.107z"
      ></path>
    </StyledSVG>
  )
}

export default UnsupportedBrowserIcon
