import React, { useState } from 'react'
import { buildTestID } from '../../Utils/utils'
import styled from 'styled-components'
import EyeIcon from '../../assets/eye-icon'
import NoEyeIcon from '../../assets/no-eye-icon'

const StyledShowHidePassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
    
    svg {
      position: absolute;
      top: 18px;
      right: 35px;
    }
`

const ShowHidePassword = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <StyledShowHidePassword>
      <input
        {...props}
        className={props.className}
        type={showPassword ? 'text' : 'password'}
        data-testid={buildTestID(
          props.datatestid,
          '/home',
          'show_hide_input',
        )}
      />
      <span onClick={() => setShowPassword(showPassword => !showPassword)} data-testid="password" >
            {!showPassword ? (<EyeIcon color="black" />) : (<NoEyeIcon color="black" />)}
          </span>
      <span onClick={() => setShowPassword(!showPassword)} data-testid="password" >
            {!showPassword ? (<EyeIcon color="#475468" />) : (<NoEyeIcon color='#475468' />)}
          </span>
    </StyledShowHidePassword>
  )
}

export default ShowHidePassword
