import React from 'react'
import { Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from '../Global/Theme'
const StyledLoadingPage = styled.div`
  width: 100%;
  height: ${({ desktopHeight }) => desktopHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-icon {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    height: ${({ mobileHeight }) => mobileHeight}px;
  }
`
const LoadingPage = ({
  isHomePage=false,
  hideSpinner = false,
  desktopHeight = 420,
  mobileHeight,
}) => {
  return (
    <Theme>
    <StyledLoadingPage
      desktopHeight={isHomePage?800: desktopHeight}
      mobileHeight={mobileHeight}
    >
      {!hideSpinner && (
        <Spinner animation="border" className="spinner-icon" />
      )}
    </StyledLoadingPage>
    </Theme>
  )
}

LoadingPage.propTypes = {
  hideSpinner: PropTypes.bool,
  desktopHeight: PropTypes.number,
  mobileHeight: PropTypes.number,
}

export default LoadingPage