import { API, graphqlOperation } from 'aws-amplify'
import { getItemOrUpdate, removeEntry } from './cache'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import advanceFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

import {
  getTransactions as getTransactionGraphQl,
  getLoan as getLoanGraphQl,
  saveCognitoId,
  requestCode,
  confirmCode,
  putLocRequest,
  getLocStatus,
  getLocEligibility,
  getPayOffEligibility,
  logoutSubscription,
  getPayoffQuote,
  putPayoffRequest,
  putStatementPreference,
  postESignature,
  getAgreementSignature,
  updateAgreementSign,
  getAnnualPrivacyNotice,
  postIndividualContact,
  postIndividualHomePhoneNumber,
  postIndividualMobilePhoneNumber,
  postIndividualWorkPhoneNumber,
  getValidateIdentity
} from '../Queries'
import {
  getCurrentUserAttribute,
  getUserCognitoIdentityPoolId,
} from '../services/auth'

const GET_USER_KEY = 'GET_USER_KEY'
const GET_TRANSACTIONS_KEY = 'GET_TRANSACTIONS_KEY'
const GET_LOAN_KEY = 'GET_LOAN_KEY'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanceFormat)

export const getTransactions = async (refresh = false) => {
  const personId = await getCurrentUserAttribute('custom:person_id')
  return await getItemOrUpdate(
    GET_TRANSACTIONS_KEY + personId,
    () =>
      API.graphql(graphqlOperation(getTransactionGraphQl(personId))),
    refresh,
  )
}

export const getLoan = async (refresh = false) => {
  const personId = await getCurrentUserAttribute('custom:person_id')
  return await getItemOrUpdate(
    GET_LOAN_KEY + personId,
    () => API.graphql(graphqlOperation(getLoanGraphQl(personId))),
    refresh,
  )
}

export const storeCognitoId = async () => {
  const personId = await getCurrentUserAttribute('custom:person_id')
  const cognitoId = await getUserCognitoIdentityPoolId()
  const gql = saveCognitoId(personId, cognitoId)
  const response = await API.graphql(graphqlOperation(gql))
  return response
}

export const runAllApiMethodsToFillCache = async () => {
  console.log('filling api cache')
  getTransactions()
  getLoan()
}

export const removeAllApiCacheEntries = () => {
  getLocalStorageKeysContaining(GET_LOAN_KEY).forEach((key) =>
    removeEntry(key),
  )
  getLocalStorageKeysContaining(GET_TRANSACTIONS_KEY).forEach((key) =>
    removeEntry(key),
  )
  getLocalStorageKeysContaining(GET_USER_KEY).forEach((key) =>
    removeEntry(key),
  )
}

export const requestAuthCode = async (sendType) =>
  await API.graphql(graphqlOperation(requestCode(sendType)))

export const confirmAuthCode = async (code) =>
  await API.graphql(graphqlOperation(confirmCode(code)))

export const submitLoc = async (
  loanNumber,
  requestDateTime,
  submissionType,
  drawAmount,
  drawSigner,
  payType,
) =>
  await API.graphql(
    graphqlOperation(
      putLocRequest(
        loanNumber,
        requestDateTime,
        submissionType,
        drawAmount,
        drawSigner,
        payType,
      ),
    ),
  )

export const logout = async (subId, callback) =>
  await API.graphql(
    graphqlOperation(logoutSubscription(subId)),
  ).subscribe({
    next: callback,
    error: (err) => console.warn(err),
  })

export const retrieveLocStatus = async (loanNumber) =>
  await API.graphql(graphqlOperation(getLocStatus(loanNumber)))

export const retrieveLocEligibility = async (loanNumber) =>
  await API.graphql(graphqlOperation(getLocEligibility(loanNumber)))

export const retrievePayOffEligibility = async (loanNumber) =>
  await API.graphql(
    graphqlOperation(getPayOffEligibility(loanNumber)),
  )

export const getPayOffQuote = async (loanNumber, payoffDate) => {
  return await API.graphql(
    graphqlOperation(getPayoffQuote(loanNumber, payoffDate)),
  )
}

export const retrieveAnnualPrivacyNotice = async (loanNumber) => {
  return await API.graphql(
    graphqlOperation(getAnnualPrivacyNotice(loanNumber)),
  )
}

const getLocalStorageKeysContaining = (prefix) => {
  if (!prefix) return null
  return Object.keys(localStorage).filter(
    (key) => key.toLowerCase() === prefix.toLowerCase(),
  )
}

export const updateStatementPreference = async (
  statementPreference,
) =>
  await API.graphql(
    graphqlOperation(putStatementPreference(statementPreference)),
  )

export const submitESignAgreement = async (
  borrower,
  optInTimestamp,
  consentTimestamp,
  thirdPartyTimestamp,
  addressTimestamp,
  dob,
  lastFourSsn,
  thirdParty,
  nbsInformation
) =>
  await API.graphql(
    graphqlOperation(
      postESignature,
      {
        "input": {
          "loanNumber": borrower.loanNumber,
          "personId": borrower.personId,
          "signatureData": {
            "birthDate": dob,
            "lastFourTaxPayerId": lastFourSsn,
            "loginDetails": borrower.email,
            "firstName": borrower.firstName,
            "lastName": borrower.lastName,
            "signatureText": {
              "birthDate": dob,
              "lastFourTaxPayerId": lastFourSsn,
              "addressValidation": addressTimestamp
            },
            "createdBy": borrower.email
          },
          "pdfGenerate": {
            "address": borrower.houseNumberStreeName,
            "city": borrower.city,
            "postalCode": borrower.postalCode,
            "state": borrower.state,
            "signatureDateTime": dayjs().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY hh:mm A z')
          },
          "thirdParty": thirdParty,
          "nonBorrowingSpouse": nbsInformation,
          "agreements": [
            {
              "descriptionId": 1,
              "signatureDateTime": optInTimestamp
            },
            {
              "descriptionId": 2,
              "signatureDateTime": consentTimestamp
            },
            {
              "descriptionId": 3,
              "signatureDateTime": dayjs.utc().local().format()
            }
          ]
        }
      }
    )
  )

export const getESignAgreementConsent = async (personId) => {
  return await API.graphql(
    graphqlOperation(
      getAgreementSignature,
      {
        "descriptionId": 2,
        "personId": personId
      }))
}

export const updatePayoffRequest = async (destination,requestedGoodThroughDate,type) => {
  return await API.graphql(
    graphqlOperation(
      putPayoffRequest,
      {
        destination,
        requestedGoodThroughDate,
        type
      }
  ))
}

export const postESignAgreementConsent = async (signatureId, email) => {
  return await API.graphql(
    graphqlOperation(
      updateAgreementSign(signatureId, email)))
}

export const updateIndividualContact = async (
  input,
) => {
  await API.graphql(
    graphqlOperation(
      postIndividualContact(input.cellConsent, input.mobilePhoneNumber)
    ))
}

export const updateIndividualPhoneNumber = async (
  input,
) => {
  if (input.homePhoneNumber) {
    await API.graphql(
      graphqlOperation(
        postIndividualHomePhoneNumber(input.cellConsent, input.homePhoneNumber)
      ))
  }

  if (input.mobilePhoneNumber) {
    await API.graphql(
      graphqlOperation(
        postIndividualMobilePhoneNumber(input.cellConsent, input.mobilePhoneNumber)
      ))
  }

  if (input.workPhoneNumber) {
    await API.graphql(
      graphqlOperation(
        postIndividualWorkPhoneNumber(input.cellConsent, input.workPhoneNumber)
      ))
  }
}

export const checkValidateIdentity = async (loanNumber, ssn, dateOfBirth, zipCode) => {
  return await API.graphql(
    graphqlOperation(
      getValidateIdentity(loanNumber, ssn, dateOfBirth, zipCode)))
}