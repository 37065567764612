import * as React from "react";
import styled from "styled-components";
import DownloadIcon from "../../assets/download-icon";
import EyeIconTwo from "../../assets/eye-icon-two";

const StyledCommonFormItem = styled.a`
  border-radius: 20px;
  background: linear-gradient(180deg, #475468 0%, #62728b 100%);
  box-shadow: 0px 1px 0px 0px #232830;
  display: flex;
  justify-content: space-between;
  // gap: 20px;
  padding: 32px 25px;
  height: 88px;
  margin: 10px 0;

  @media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
    max-width: 340px;
    width: 340px;
  }
`;

const Title = styled.div`
  color: var(--White, #fff);
  flex-grow: 1;
  flex-basis: auto;
  font: 700 18px/133% Lato, sans-serif;
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 18px;
`;

const Image = styled.div`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const CommonFormItem = ({ form }) => {
  return (
    <StyledCommonFormItem href={form.href} target="_blank">
      <Title>{form.text}</Title>
      <ImagesContainer>
        <Image>
          <DownloadIcon />
        </Image>
        <Image>
          <EyeIconTwo />
        </Image>
      </ImagesContainer>
    </StyledCommonFormItem>
  );
}

export default CommonFormItem