import React, { useState } from "react";
import styled from "styled-components";
import { CREATE_ACCOUNT, SIGN_IN } from "./AuthLayout";

const StyledLandingPageTabs = styled.div`
display: flex;
justify-content: flex-start;
margin-left: 26px;

& div {
  display: flex;
  padding: 10px 20px;
  width: 180px;
  justify-content: center;
  align-items: center;

  border-radius: 10px 10px 0px 0px;
  background: rgba(220, 220, 220, 0.95);
  color: var(--Grey-2, #777);

  &.active {
    background: rgba(255, 255, 255, 0.95);
    font: 500 20px Lato, sans-serif;
    color: var(--Black, #000);
  }
}

@media (min-width: ${({ theme }) => theme.breakPoints.medium}) {
  margin-left: 40px;

  & div {
    width: 200px;
  }
}
`;

const LandingPageTabs = ({currentTab, setActiveFormTab, setCurrentTab }) => {
  const tabs = [{name: SIGN_IN, display: 'Sign In'}, {name: CREATE_ACCOUNT, display: 'Create Account'}];

  const setActiveTab = (tab) => {
    setCurrentTab(tab)
    setActiveFormTab(tab)
  };

  return (
    <StyledLandingPageTabs>
      {
        tabs.map((tab, index) => (
          <div
            key={index}
            className={currentTab === tab.name ? 'active' : ''}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.display}
          </div>
        ))
      }
    </StyledLandingPageTabs>
  );
}

export default LandingPageTabs;